import { z } from 'zod';

import { userDtoSchema } from './user.dto';

/** User secret DTO schema. */
export const userSecretDtoSchema = z.object({

	/** Access token. */
	token: z.string(),

	/** Token expiry. */
	expiry: z.string(),

	/** User. */
	user: userDtoSchema,
}).strict();

/** User secret DTO schema. */
export const userSecretCreationDtoSchema = z.object({

	/** Access token. */
	token: z.string(),

}).strict();

/** User secret DTO. */
export type UserSecretDto = Readonly<z.infer<typeof userSecretDtoSchema>>;

/** User secret creation DTO. */
export type UserSecretCreationDto = Readonly<z.infer<typeof userSecretCreationDtoSchema>>;
