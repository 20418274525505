import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { z, ZodType } from 'zod';

import { JobMemberWithLogs } from '../models/job-member';

import { StorageService } from './storage.service';
const LOGS_STORAGE_KEY = 'temporaryLogs';

/** Logs storage. */
@Injectable({ providedIn: 'root' })
export class JobLogListStorageService {

	private readonly storageService = inject(StorageService);

	/** Current logs. */
	public readonly currentLogs$: Observable<JobMemberWithLogs[] | null>;

	public constructor() {
		this.currentLogs$ = this.storageService.get<ZodType<JobMemberWithLogs[]>>(LOGS_STORAGE_KEY, z.array(z.any()));
	}

	/**
	 * Saves logs.
	 * @param logs Logs to save.
	 */
	public saveLogs(
		logs: JobMemberWithLogs[],
	): Observable<JobMemberWithLogs[]> {
		return this.storageService.save(LOGS_STORAGE_KEY, logs).pipe(map(() => logs));
	}

	/** Removes all logs. */
	public clearLogs(): Observable<void> {
		return this.storageService.remove(LOGS_STORAGE_KEY);
	}
}
